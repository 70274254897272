import request from '@/utils/request'

// 查询收益管理列表
export function listPartnerProfile(query) {
  return request({
    url: '/work/partnerProfile/list',
    method: 'get',
    params: query
  })
}

// 查询收益管理详细
export function getPartnerProfile(id) {
  return request({
    url: '/work/partnerProfile/' + id,
    method: 'get'
  })
}

// 新增收益管理
export function addPartnerProfile(data) {
  return request({
    url: '/work/partnerProfile',
    method: 'post',
    data: data
  })
}

// 修改收益管理
export function updatePartnerProfile(data) {
  return request({
    url: '/work/partnerProfile/update',
    method: 'post',
    data: data
  })
}

// 删除收益管理
export function delPartnerProfile(id) {
  return request({
    url: '/work/partnerProfile/remove/' + id,
    method: 'get'
  })
}

// 导出收益管理
export function exportPartnerProfile(query) {
  return request({
    url: '/work/partnerProfile/export',
    method: 'get',
    params: query
  })
}

// 查询小程序管理列表
export function listWxapp(query) {
  return request({
    url: '/work/partnerProfile/wxappList',
    method: 'get',
    params: query
  })
}